import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {games, winrateToDifficulty} from "@/games";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  ...Object.values(winrateToDifficulty).map(d => {
    return {
      path: `/${d.urlpath}`,
      name: `difficulty-${d.urlpath}`,
      component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
      props: {
        difficulty: d
      },
      meta: d
    }
  }),
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUsView.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsOfUseView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicyView.vue')
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "about" */ '../views/CookiesView.vue')
  },
  {
    path: '/games/solitaire-collection/privacy-policy',
    name: 'solitaire-collection-privacy-policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/GamePrivacyPolicyView.vue')
  },
  {
    path: '/admob-providers',
    name: 'admob-providers',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdmobProviders.vue')
  },
  ...games.map(g => {
    return {
      path: `/${g.urlpath}`,
      name: `game-${g.classname}`,
      component: () => import(/* webpackChunkName: "about" */ '../views/GameView.vue'),
      props: g,
      meta: g
    }
  })
]

const router = createRouter({
  history: process.env.NODE_ENV === "development" ?
    createWebHistory(process.env.BASE_URL) :
    createWebHistory(process.env.BASE_URL),
  routes
});

// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.

  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  document.title = nearestWithTitle ?
      nearestWithTitle.meta.title :
      "Classic Card Games | Play Online 100% Free";

  // Remove any stale meta tags from the document using the key attribute we set below.
  const s = document.getElementById("page-description");
  if (s) {
    const nearestWithDescription = to.matched.slice().reverse().find(r => r.meta && r.meta.description);
    s.content = nearestWithDescription ?
        nearestWithDescription.meta.description :
        "Play 200+ classic card games online for free. No download or registration required.";
  }

  next();
});

export default router
